import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { Qualification } from '../.../../../../../shared/types/Qualification';

export const useQualificationListStore = defineStore('qualificationList', () => {
  const qualificationList = ref<Qualification[]>([]);
  const imageUrlHost = import.meta.env.VITE_IMAGES_URL;

  const certificates = computed(() => {
    return qualificationList.value?.map((item) => ({
      image: {
        src: item.filename.endsWith('.pdf')
          ? item.thumbnail
          : `${imageUrlHost}/cdn-cgi/image/fit=cover,width=93,height=140${item.filename}`,
        width: '93',
        height: '140',
        preview: `${imageUrlHost}/cdn-cgi/image/fit=scale-down,height=800${item.filename}`,
        alt: item.name,
      },
      title: item.name,
      desc: item.description,
      link: item.url,
      website: item.website,
    }));
  });

  return { qualificationList, certificates };
});
